<template>
  <div class="page">
    <div class="content">
      <video
          src="https://douzhuanduokai.oss-cn-beijing.aliyuncs.com/video/dzdkdldw.mp4"
          controls
          preload="auto"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="landscape"
          style="object-fit:fill;width: 100%">
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {

    }
  },
  components: {
  }
}
</script>
<style lang="less" scoped>

</style>